.genNumberContainer {
  position: absolute;
  height: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.genNumber {
  font-size: 15vh;
}
.badge {
  width: 60%;
}

.speedBtn {
  position: absolute;
  top: 2%;
  left: 2%;
  -ms-transform: translate(2%);
  transform: translate(2%);
  z-index: 5;
  display: flex;
  flex-direction: column;
}
.increaseSpeedBtn {
  margin-bottom: 5%;
}

@media screen and (max-height: 471px) {
  .speedBtn {
    display: none !important;
  }
  .genNumberContainer {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .badge {
    max-width: 360px;
  }
}
