.header {
  margin: 2% 0;
  padding: 0 !important;
}

.mrBtn {
  margin-right: 3%;
}

.centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hFull {
  height: 100%;
}

.controlBtn {
  padding: 1rem !important;
}

@media screen and (max-width: 310px) {
  .mrBtn {
    margin-right: 1px;
  }
  .controlBtn {
    padding: 0.75rem;
  }
}
.container {
  height: fit-content;
}

@media screen and (min-width: 600px) {
  .container {
    max-width: 600px !important;
  }
}

@media screen and (max-height: 471px) {
  .container {
    height: fit-content;
  }
}
