.typeSwitcher {
  text-align: left !important;
}

.container {
  padding-bottom: 1%;
  margin: auto;
  overflow: auto;
  height: 87%;
}

.colorBadge {
  padding: 0.5rem;
}

.centerXY {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

@media screen and (min-width: 600px) {
  tbody td {
    font-size: xxx-large;
  }
  .container {
    max-width: 480px;
  }
}

.mrBtn {
  margin-right: 2%;
  padding: 0.5rem 1rem;
}

@media screen and (max-height: 450px) {
  .container {
    height: 85%;
  }
}
@media screen and (max-height: 350px) {
  .container {
    height: 83%;
  }
}
@media screen and (max-height: 300px) {
  .container {
    height: 81%;
  }
}
@media screen and (max-height: 270px) {
  .container {
    height: 79%;
  }
}
@media screen and (max-height: 230px) {
  .container {
    height: 75%;
  }
}
