.container {
  text-align: center;
  top: 100%;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  position: absolute;
  height: 82%;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-height: 471px) {
  .container {
    height: 100%;
  }
}
